//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-504:_8028,_3788,_2356,_9504,_3708,_5676,_8808,_7236;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-504')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-504', "_8028,_3788,_2356,_9504,_3708,_5676,_8808,_7236");
        }
      }catch (ex) {
        console.error(ex);
      }