//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-504:_4076,_9124,_1920,_8048,_2008,_8060,_6656,_5716;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-504')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-504', "_4076,_9124,_1920,_8048,_2008,_8060,_6656,_5716");
        }
      }catch (ex) {
        console.error(ex);
      }